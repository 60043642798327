body {
  min-height: 100%;
  min-height: 100vh;
  height: auto;
  color: #fff;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: rgb(35,35,36);
background: linear-gradient(20deg, rgba(35,35,36,1) 0%, rgba(67,67,69,1) 100%);
}
textarea, input, button { outline: none; }

/* App */
.App {
  position: relative;
  width: 100%;
  height: auto;
  text-align: center;
}

#app {
  position: relative;
}
.main {
  position: relative;
  padding-top: 80px;
  z-index: 10;
}

/* Custom Fonts */
/* Cerebri */
@font-face {
  font-family: 'Cerebri';
  src: url('/public/fonts/Cerebri/CerebriSans-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Cerebri';
  src: url('/public/fonts/Cerebri/CerebriSans-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Cerebri';
  src: url('/public/fonts/Cerebri/CerebriSans-Light.woff2') format('woff2');
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: 'Cerebri';
  src: url('/public/fonts/Cerebri/CerebriSans-SemiBold.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Cerebri';
  src: url('/public/fonts/Cerebri/CerebriSans-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Geomanist';
  font-weight: 700;
  font-style: normal;
  src: url('/public/fonts/geomanist/geomanist-black-webfont.eot'); /* IE9 Compat Modes */
  src: url('/public/fonts/geomanist/geomanist-black-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/public/fonts/geomanist/geomanist-black-webfont.woff') format('woff2'), /* Super Modern Browsers */
       url('/public/fonts/geomanist/geomanist-black-webfont.woff2') format('woff'), /* Pretty Modern Browsers */
       url('/public/fonts/geomanist/geomanist-black-webfont.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/public/fonts/geomanist/geomanist-black-webfont.svg') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Geomanist';
  font-weight: 600;
  font-style: normal;
  src: url('/public/fonts/geomanist/geomanist-bold-webfont.eot'); /* IE9 Compat Modes */
  src: url('/public/fonts/geomanist/geomanist-bold-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/public/fonts/geomanist/geomanist-bold-webfont.woff') format('woff2'), /* Super Modern Browsers */
       url('/public/fonts/geomanist/geomanist-bold-webfont.woff2') format('woff'), /* Pretty Modern Browsers */
       url('/public/fonts/geomanist/geomanist-bold-webfont.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/public/fonts/geomanist/geomanist-bold-webfont.svg') format('svg'); /* Legacy iOS */
}

/* Forms */
.input-field {
  
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  label {
    font-size: 1em;
    color: rgb(10,10,10);
  }

  input {
    width: 100%;
    background: rgba(35,35,36, .6) !important;
    border: 1px solid rgba(10,10,10,.5);
    padding: 5px;
    box-sizing: border-box;
    // border-radius: 5px;
    margin: 5px 0px 15px;
    font-size: 1.4em;
    color: #fff;
    &:-webkit-autofill {
      // background: rgb(35,35,36);
      color: #fff;
    }
    &:-internal-autofill-selected {
      // background: rgb(35,35,36);
      color: #fff;
    }
  }
}
