@import '../../variables.scss';

/* WritePage */
.write-page {
  position: relative;
  display: block;
  width: 100%;
  max-width: 700px;
  margin: auto;
  padding-bottom: 80px;
  @include breakpoint(mobileonly){
    padding: 0 20px;
    box-sizing: border-box;
  } 
}
.write-textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  min-height: 300px;
  min-height: 60vh;
  background: rgba(0,0,0,.3);
  border-radius: 8px;
  border: none;
  box-shadow: -20px 20px 80px rgba(0,0,0,.5);
  margin: 20px auto 0px;
  padding: 40px 30px 40px 35px;
  color: rgba(255,255,255,.98);
  font-size: 1.5em;
  font-family: 'Cormorant Garamond', serif;
  font-family: 'Quicksand', sans-serif;
  font-weight: lighter;
  resize: none;
  @include breakpoint(mobileonly){
    padding: 15px 20px 20px 15px;
    font-size: 1.4em;
  } 
}

button.jw-button.save-prompt {
  float: right;
  margin-top: -40px;
  margin-right: -25px;
}

