.terms-and-privacy {
    box-sizing: border-box;
    padding: 60px;
    max-width: 900px;
    margin: 1em auto 3em;
}

.terms-and-privacy a {
    color: #eee;
    font-weight: bold;
    text-decoration: none !important;
}