/* Header */
#header {
  display: block;
  box-sizing: border-box;
  background: none;
  position: fixed;
  width: 100%;
  height: 80px;
  padding: 10px;
  color: rgba(0,0,0,.7);
  z-index: 150;
}
#header a.site-title {
  position: relative;
  float: left;
  margin-top: 3px;
  margin-right: 10px;
}
#header a.site-title h1 {
  float: left;
  display: inline;
  color: rgba(255,255,255,.7);
  font-weight: lighter;
  margin: 0;
  -webkit-transition: color .3s ease; /* Safari */
  transition: color .3s ease;
}
#header a.site-title:hover h1 {
  color: rgba(255,255,255,.9);
}

#header {
  
  button {
    background: none;
    border: none;
    box-shadow: none;
  }

  .right-items {
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
  }


}

.header-auth {
  margin-right: .5em;
}
.header-auth > div {
  padding: 0;
  margin-top: -2px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-auth p {
  font-size: 14px;
  margin-right: .5em;
  color: #fff;
}
.header-auth button {
  color: #fff;
  height: 14px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  padding: 0;
  margin: 0;
}