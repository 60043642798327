.about {
    box-sizing: border-box;
    padding: 60px;
    max-width: 900px;
    margin: 1em auto 3em;
    font-size: 1.4em;
}

.about a {
    color: #eee;
    font-weight: bold;
    text-decoration: none !important;
}