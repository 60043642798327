/* Prompts */
.prompt {
  max-width: 100%;
  margin: 0px auto 0px;
}
.prompt-types {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 267px;
  width: 267px;
  margin: auto;

  button.jw-button {
    margin: .5em;
  }

}

button.jw-button {
  &.jo-chasing-icon {
    background: linear-gradient(20deg, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);
    border: none;  
  }
}

// .prompt-type-button {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   color: #fff;
//   background: linear-gradient(20deg, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);
//   border-radius: 31px;
//   width: 60px;
//   height: 60px;
//   padding: 0;
//   margin: .5em;
//   cursor: pointer;
//   -webkit-transition: border-color .3s ease; /* Safari */
//   transition: border-color .3s ease;
// }
// .prompt-type-button:hover {
//   background: linear-gradient(20deg, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);
// }
// .prompt-type-button:hover span {
//   color: #fafafa;
// }
// .prompt-type-button span {
//   display: block;
//   font-size: 18px;
//   font-weight: lighter;
//   -webkit-transition: color .3s ease; /* Safari */
//   transition: color .3s ease;
//   margin: auto;
// }
// .prompt-type-button svg {
//   display: block;
//   margin: auto;
// }
// .prompt-image-button:hover svg path {
//   fill: #fafafa;
// }

