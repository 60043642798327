.jo-chasing-icons {
  margin: auto;
  width: 200px;
  height: 200px;
  position: relative;
  text-align: center;
  -webkit-animation: jo-chasingIconsRotate 2s infinite linear;
          animation: jo-chasingIconsRotate 2s infinite linear; }
  .jo-chasing-icons .jw-button.jo-chasing-icon {
    width: 30%;
    height: 30%;
    display: inline-block;
    position: absolute;
    top: 0;
    border-radius: 100%;
    -webkit-animation: jo-chasingIconsBounce 2s infinite ease-in-out;
            animation: jo-chasingIconsBounce 2s infinite ease-in-out; }
  .jo-chasing-icons .jw-button.jo-chasing-icon.jo-icon-2 {
    top: auto;
    bottom: 0;
    -webkit-animation-delay: -1s;
            animation-delay: -1s; }

@-webkit-keyframes jo-chasingIconsRotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes jo-chasingIconsRotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes jo-chasingIconsBounce {
  0%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes jo-chasingIconsBounce {
  0%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1); } }
