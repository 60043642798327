.login-signup-menu-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position:fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: rgba(35,35,36,.8);
  z-index: 200;
}
.login-signup-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // background: rgb(196, 196, 196);
  background: rgb(84,106,219);
  background: linear-gradient(45deg, rgba(84,106,219,1) 0%, rgba(132,87,204,1) 33%, rgba(199,88,162,1) 67%, rgba(255,96,127,1) 100%);
  //  border-radius: 15px;
  box-shadow: -20px 20px 80px rgba(0,0,0,.5);
  max-width: 500px;
  width: 80%;
  height: auto;
  box-sizing: border-box;
  padding: 10px 10px 30px;
  h4 {
    // color: #333;
    font-size: 1.4em;
    margin: .5em auto .2em;
  }
  h5 {
    // font-size: 1.4em;
    margin: .7em auto .2em;
  }

  .signup-form-text {
    margin-top: 2em;
  }

  .login-form-buttons, .signup-form-buttons {

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: .5em;

    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 0 2px;
      background: rgba(35,35,35,.85);
      font-size: .8em;
      color: #fff;
      padding: 8px 12px;
      cursor: pointer;

      &:hover {
        background: rgb(0,0,0);
      }

      &.google-auth-button {
        // background: rgb(223, 68, 68);
        // border: 1px solid rgb(170, 51, 51);
      }
      .mail-icon {
        width: auto;
        height: 28px;
        margin-right: 5px;
        margin-left: -4px;
        margin-top: -8px;
      }
      .google-icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }

    

    }

    

  }
  
  .signup-form-buttons {
    margin-top: 1em;
  }

}