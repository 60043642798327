@import '../../../variables.scss';

button.jw-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: rgb(144, 70, 247);
  background: linear-gradient(20deg, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);
  border-radius: 30px;
  border: none;
  box-shadow: -3px 3px 10px rgba(0,0,0,.1);
  width: auto;
  height: 60px;
  margin: 0px;
  padding: 0 20px;
  font-size: 16px;
  cursor: pointer;
  -webkit-transition: border-color .3s ease, background .5s ease-in-out; /* Safari */
  transition: border-color .3s ease, background .5s ease-in-out;
  
  &.delete-button {
    background: rgb(183, 36, 65);
    background: linear-gradient(20deg, rgb(183, 36, 65) 0%, rgba(252,70,107,1) 100%);
  }



  &.save-prompt {
    .button-icon {
      opacity: 0.8;
      svg {
        path {
          fill: #fff;
        }
      }
      
    }
    &.inactive {      
      background: rgb(34,34,35);
      background: linear-gradient(32deg, rgba(34,34,35,1) 0%, rgba(64,64,66,1) 100%);
      .button-icon {
        svg {
          path {
            fill: #1b1b1b;
          }
        }
      }
    }
    &.active {
      .button-icon {
        opacity: 1;
      }
    }
   
  }

  &.small {
    height: 40px;
    border-radius: 20px;
    font-size: 16px;
    .button-icon {
      width: 18px;
      height: 18px;
    }
  }

  &.large {
    height: 70px;
    border-radius: 35px;
    font-size: 24px;
    &.circle-button {
      
     
    }
    .button-icon {
      width: 25px;
      height: 25px;
    }
    @include breakpoint(mobileonly){
      height: 50px;
      border-radius: 25px;
      font-size: 16px;

      .button-icon {
        width: 20px;
        height: 20px;
      }
      
    }
  }

  &.circle-button {
    width: 60px;
    padding: 0px;
    &.large {
      width: 70px;
      @include breakpoint(mobileonly){
        width: 50px;
      }
    }
    &.small {
      width: 40px;
    }
  }
  &:hover {
    span {
      color: #fafafa;
    }
  }

  span {
    display: block;
    font-size: 18px;
    font-weight: lighter;
    -webkit-transition: color .3s ease; /* Safari */
    transition: color .3s ease;
    margin: auto;
  }
  
  svg {
    display: block;
    margin: auto;
    path {
      fill: #fafafa;
    }
  }

  .button-text {
    margin-right: 10px;
  }

  .button-icon {
    position: relative;
    width: 20px;
    height: 20px;
    svg {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      g {
        position: relative;
      }
    }
  }

}